export function useImgCdnPath() {
  const imgLoadError = ref(false);

  function onImgError() {
    imgLoadError.value = true;
  }

  function getImgPath(path: string) {
    return imgLoadError.value ? path : `${path}.webp`;
  }

  return {
    onImgError,
    getImgPath,
  };
}
